import { PAGES } from "@/constants/pagesNames";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

function useSearchParams(
  paramKey: string,
  pagePathToRedirectWhenRemoveSearchParam?: string
) {
  const route = useRoute();
  const router = useRouter();

  const queryParamsObject = computed(() => {
    let params = route.query;
    if (!Object.keys(params).length)
      params = Object.fromEntries(new URLSearchParams(window.location.search));

    return params;
  });

  const dataBySearchParamKey = computed(
    () => queryParamsObject.value[paramKey] as string
  );

  async function setSearchParam(paramValue: string, shouldReplace?: boolean) {
    const routerAction = shouldReplace ? router.replace : router.push;
    await routerAction({
      path: window.location.pathname,
      query: { ...queryParamsObject.value, [paramKey]: paramValue },
    });
  }

  async function removeSearchParam(
    shouldRedirectToOtherPage?: boolean,
    pagePath = pagePathToRedirectWhenRemoveSearchParam
  ) {
    if (paramKey in route.query) {
      const newQuery = structuredClone(queryParamsObject.value);
      delete newQuery[paramKey];

      await router.replace({
        path: shouldRedirectToOtherPage ? pagePath : window.location.pathname,
        query: newQuery,
      });
    }
  }

  return {
    dataBySearchParamKey,
    setSearchParam,
    removeSearchParam,
  };
}

export function useSelectedPipelineIdInURL() {
  const SELECTED_PIPELINE_URL_KEY = "selected_pipeline_id";
  const { dataBySearchParamKey, setSearchParam, removeSearchParam } =
    useSearchParams(SELECTED_PIPELINE_URL_KEY, PAGES.DASHBOARD.PATH);

  return {
    pipelineIdInURL: dataBySearchParamKey,
    setPipelineIdToURL: setSearchParam,
    removePipelineIdFromURL: removeSearchParam,
  };
}

export function useSelectedGroupInURL() {
  const SELECTED_GROUP_URL_KEY = "selected_group";
  const { dataBySearchParamKey, setSearchParam, removeSearchParam } =
    useSearchParams(SELECTED_GROUP_URL_KEY);

  return {
    groupInURL: dataBySearchParamKey,
    setGroupToURL: setSearchParam,
    removeGroupFromURL: removeSearchParam,
  };
}

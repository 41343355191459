import { computed, ref, toRaw, watch, type Ref } from "vue";
import { isEqual } from "es-toolkit";
import { mergeChanges } from "@/utils/objects";

export function useStoreUnconfirmedFilters<T extends Ref>(filters: T) {
  const unconfirmedFilters = ref(structuredClone(toRaw(filters.value)));
  function setUnconfirmedFilters(
    newFilters: Partial<typeof unconfirmedFilters.value>
  ) {
    mergeChanges(unconfirmedFilters.value, newFilters);
  }
  function resetUnconfirmedFilters() {
    mergeChanges(unconfirmedFilters.value, filters.value);
  }
  function confirmUnconfirmedFilters() {
    mergeChanges(filters.value, unconfirmedFilters.value);
  }
  const areNewFiltersUnconfirmed = computed(
    () => !isEqual(filters.value, unconfirmedFilters.value)
  );

  watch(
    filters,
    (newFilters) => {
      setUnconfirmedFilters(structuredClone(toRaw(newFilters)));
    },
    { deep: true }
  );

  return {
    unconfirmedFilters,
    setUnconfirmedFilters,
    resetUnconfirmedFilters,
    confirmUnconfirmedFilters,
    areNewFiltersUnconfirmed,
  };
}

<template>
  <v-list-group>
    <template v-slot:activator="{ props }">
      <v-list-item
        :active="false"
        v-bind="props"
        prepend-icon="mdi-view-list"
        :title="`${$t('side_bar.groups')}:`"
      ></v-list-item>
    </template>
    <template v-if="showNested">
      <v-list-item
        v-for="group in authStore.serviceGroups"
        :key="group"
        density="compact"
        :class="['nested', { selected: group === authStore.selectedGroup }]"
        :title="group"
        @click="onNavigationGroupSelect(group)"
        ><template v-slot:prepend>{{
          group.slice(0, 3).toUpperCase()
        }}</template></v-list-item
      >
    </template>
  </v-list-group>
</template>

<script>
import { useAuthStore, useGlobalStore, usePipelinesStore } from "@/store";
import { PAGES } from "@/constants/pagesNames";

export default {
  name: "NavBarGroupItem",
  props: ["showNested"],

  setup() {
    return {
      authStore: useAuthStore(),
      pipelinesStore: usePipelinesStore(),
      globalStore: useGlobalStore(),
    };
  },

  methods: {
    async onNavigationGroupSelect(group) {
      if (group !== this.authStore.selectedGroup) {
        this.pipelinesStore.setIsLoadingPipelines(true);

        await this.globalStore.clearAllSelectedData();

        const path = this.$router.currentRoute.value.path;
        const { DASHBOARD, SCANNER_CONTROL } = PAGES;
        if (path !== DASHBOARD.PATH && path !== SCANNER_CONTROL.PATH) {
          await this.$router.push({
            path: DASHBOARD.PATH,
            query: this.$route.query,
          });
        }

        this.authStore.setSelectedGroup(group);

        this.authStore.getGroupData();
      }
    },
  },
};
</script>

<style lang="scss">
.nested.v-list-item {
  margin: 0 !important;
  .v-list-item__prepend {
    padding-right: 10px;
  }
}
</style>

<style lang="scss" scoped>
.nested.v-list-item {
  margin-left: 14px !important;
  padding-inline-start: 0 !important;
  -webkit-padding-start: 0 !important;
  padding: 4px 12px !important;
  &:not(:last-child) {
    margin-bottom: 4px !important;
  }
}
.selected {
  background: #3b4151;
}
</style>

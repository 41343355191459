import { defineStore } from "pinia";
import {
  useAnomaliesStore,
  useJointsStore,
  usePipelinesStore,
  usePipePartsStore,
  usePipeScannersStore,
  useSafetyFactorStore,
  useScannerControlStore,
} from "@/store";
import { useSelectedPipelineIdInURL } from "@/utils/urlHelpers";

export const useGlobalStore = defineStore("global", () => {
  const anomaliesStore = useAnomaliesStore();
  const jointsStore = useJointsStore();
  const pipePartsStore = usePipePartsStore();
  const pipeScannersStore = usePipeScannersStore();
  const safetyFactorStore = useSafetyFactorStore();
  const scannerControlStore = useScannerControlStore();
  const pipelinesStore = usePipelinesStore();

  const { removePipelineIdFromURL } = useSelectedPipelineIdInURL();

  function resetFilters() {
    anomaliesStore.resetFilters();
    jointsStore.resetFilters();
    pipePartsStore.resetFilters();
    safetyFactorStore.resetFilters();
    anomaliesStore.resetFilters();
  }
  async function clearSelectedPipeData() {
    await removePipelineIdFromURL();
    pipelinesStore.resetSelectedPipelineIdAndAllDataForIt();
  }
  async function clearAllSelectedData() {
    await clearSelectedPipeData();
    pipelinesStore.setGroupPipelines([]);
    pipeScannersStore.resetScanners();
    scannerControlStore.resetState();
  }

  return {
    resetFilters,
    clearSelectedPipeData,
    clearAllSelectedData,
  };
});

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import jwt_decode from "jwt-decode";
import { auth0 } from "@/config/auth0";
import { unitsConverter } from "@/config/units";
import { userCommentsAPI } from "@/features/comments/api";
import { DefaultService } from "@/open-api-code/ili-api";
import { getDepth, getGeoTIFF } from "@/utils/geoTiffHelpers";
import { useAuthStore } from "./useAuthStore";
import { PAGES } from "@/constants/pagesNames";
import { useSelectedPipelineIdInURL } from "@/utils/urlHelpers";
import { useI18n } from "vue-i18n";
import { useGlobalStore } from "./useGlobalStore";
import type { Comment } from "@/types/comments";
import type { InspectionStats, Pipeline } from "@/types/pipelines";
import { showToastError } from "@/utils/errors";
import { DashboardWidget } from "@/pages/dashboard/views/PipelinesMapmode/config";

const requests = new Map();

export const usePipelinesStore = defineStore("pipelines", () => {
  const toast = useToast();
  const { t } = useI18n();
  const authStore = useAuthStore();
  const globalStore = useGlobalStore();
  const { pipelineIdInURL, removePipelineIdFromURL } =
    useSelectedPipelineIdInURL();

  const isLoadingPipelines = ref(false);

  function setIsLoadingPipelines(isLoading: boolean) {
    isLoadingPipelines.value = isLoading;
  }
  async function getPipelines() {
    setIsLoadingPipelines(true);
    try {
      const token = await auth0.getAccessTokenSilently();
      if (token) {
        requests.set(
          "pipelinesRequest",
          DefaultService.readPipelinesInlineInspectionsPipelinesGroupGet(
            authStore.selectedGroup
          )
        );
        const pipelines = await requests.get("pipelinesRequest");
        setGroupPipelines(pipelines);
      } else {
        console.error("No token, try to log in!");
      }
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoadingPipelines(false);
    }
  }

  const isLoadingStats = ref(false);

  function setIsLoadingStats(isLoading: boolean) {
    isLoadingStats.value = isLoading;
  }

  const groupPipelines = ref<Pipeline[]>([]);

  function setGroupPipelines(pipelines: Pipeline[]) {
    groupPipelines.value = pipelines;
  }

  const selectedPipeline = computed(() =>
    groupPipelines.value.find(({ id }) => id === selectedPipelineId.value)
  );
  async function getAllDataForSelectedPipelineId() {
    globalStore.resetFilters();
    if (selectedPipeline.value) {
      setSelectedInspectionId(selectedPipeline.value.inspections[0].id);
      await Promise.all([
        getPipeComments(selectedPipeline.value.id),
        getInspectionStats(),
      ]);
    }
  }
  function resetSelectedPipelineIdAndAllDataForIt() {
    setSelectedPipelineId(null);
    setSelectedInspectionId(null);
    setSelectedInspectionStats(null);
    setComments([]);
    setPipelineElevation([]);
  }

  const selectedPipelineId = ref<Pipeline["id"] | null>(null);

  function setSelectedPipelineId(pipeline: Pipeline["id"] | null) {
    selectedPipelineId.value = pipeline;
  }
  function setSelectedPipelineIdFromURL() {
    const { DASHBOARD, ANOMALIES, JOINTS, PIPE_PARTS, SAFETY_FACTOR } = PAGES;
    const unavailablePagesIfNoPipelineId = [
      DASHBOARD.PATH,
      ANOMALIES.PATH,
      JOINTS.PATH,
      PIPE_PARTS.PATH,
      SAFETY_FACTOR.PATH,
    ];

    const shouldRedirectToDashboard = unavailablePagesIfNoPipelineId.includes(
      window.location.pathname
    );

    if (!pipelineIdInURL.value) {
      removePipelineIdFromURL(shouldRedirectToDashboard);
      resetSelectedPipelineIdAndAllDataForIt();
    } else if (groupPipelines.value.length) {
      const isPipelineIdCorrect = groupPipelines.value.some(
        ({ id }) => id === pipelineIdInURL.value
      );
      if (isPipelineIdCorrect) {
        setSelectedPipelineId(pipelineIdInURL.value);
      } else {
        removePipelineIdFromURL(shouldRedirectToDashboard);
        toast.error(t("dashboard.pipelines.doesnt_exist"), {
          position: "top-right",
          duration: 4000,
        });
      }
    }
  }

  // TODO add type for pipelineElevation
  const pipelineElevation = ref([]);
  const isLoadingPipelineElevation = ref(false);

  const convertedPipelineElevation = computed(() => {
    if (pipelineElevation.value.length === 0) {
      return [];
    }

    return pipelineElevation.value.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  });

  function setIsLoadingPipelineElevation(isLoading: boolean) {
    isLoadingPipelineElevation.value = isLoading;
  }
  function setPipelineElevation(elevation) {
    pipelineElevation.value = elevation;
  }
  async function getElevation() {
    try {
      setIsLoadingPipelineElevation(true);

      const controller = new AbortController();
      const stats = await requests.get("statsRequest");
      const elevation = await getGeoTIFF(
        stats.map_trace_geometry.coordinates,
        controller
      );

      setPipelineElevation(elevation);
      requests.set("geoTIFFRequest", controller);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingPipelineElevation(false);
    }
  }

  const comments = ref<Comment[]>([]);

  function setComments(value: Comment[]) {
    comments.value = value;
  }
  function updateComment(comment: Comment) {
    const editedIndex = comments.value.findIndex(({ id }) => id === comment.id);
    if (editedIndex >= 0) {
      comments.value[editedIndex] = comment;
    } else {
      comments.value.push(comment);
    }
  }
  function removeComment(commentId: Comment["id"]) {
    comments.value = comments.value.filter(
      (comment) => comment.id !== commentId
    );
  }
  async function getPipeComments(pipelineId: Pipeline["id"]) {
    try {
      setComments([]);

      const comments = await userCommentsAPI.getAll(
        pipelineId,
        authStore.selectedGroup
      );

      setComments(comments);
    } catch (error) {
      if (error instanceof Error && error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read user comments - ${error.message}`, {
          position: "top-right",
        });
      }
    }
  }

  const selectedInspectionId = ref<string | null>(null);

  function setSelectedInspectionId(inspection: string | null) {
    selectedInspectionId.value = inspection;
  }

  const selectedInspectionStats = ref<InspectionStats | null>(null);

  const convertedHeightProfileDistance = computed(() =>
    selectedInspectionStats.value?.height_profile?.distance?.map((item) =>
      unitsConverter.instance.convert(item, "m")
    )
  );
  const convertedHeightProfileZ = computed(() =>
    selectedInspectionStats.value?.height_profile.z.map(
      (item) => unitsConverter.instance.convert(item, "m") as number
    )
  );

  function setSelectedInspectionStats(stats: InspectionStats | null) {
    selectedInspectionStats.value = stats;
  }
  async function getInspectionStats() {
    setIsLoadingStats(true);
    setSelectedInspectionStats(null);
    try {
      if (selectedInspectionId.value) {
        const token = await auth0.getAccessTokenSilently();
        if (token) {
          const payload = jwt_decode(token);
          const groupKey = "https://acqgroups.cloud/groups";
          if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
            requests.set(
              "statsRequest",
              DefaultService.readInspectionStatsInlineInspectionsInspectionStatsInspectionIdGroupGet(
                selectedInspectionId.value,
                authStore.selectedGroup
              )
            );
            const stats = await requests.get("statsRequest");

            setSelectedInspectionStats(stats);
            await getElevation();

            requests.delete("statsRequest");
          }
        }
      } else {
        throw new Error("No selected inspection id in store");
      }
    } catch (error) {
      if (error instanceof Error && error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Inspection Stats - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoadingStats(false);
    }
  }

  const pipelineDepth = computed(() => {
    const elevation = pipelineElevation.value;
    const inspectionStats = selectedInspectionStats.value;

    if (elevation.length === 0 || !inspectionStats) {
      return [];
    }

    return getDepth(elevation, inspectionStats.height_profile.z);
  });
  const convertedPipelineDepth = computed(() => {
    if (pipelineDepth.value.length === 0) {
      return [];
    }

    return pipelineDepth.value.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  });
  const heightRange = computed(() => {
    if (convertedHeightProfileZ.value) {
      const yAxisValues = [
        ...convertedHeightProfileZ.value,
        ...convertedPipelineElevation.value,
        ...convertedPipelineDepth.value,
      ];

      const min = Math.min(...yAxisValues);
      const max = Math.max(...yAxisValues);

      return [min, max];
    } else return null;
  });

  function cancelRequests() {
    if (requests.has("statsRequest")) {
      requests.get("statsRequest").cancel();
    }
    if (requests.has("geoTIFFRequest")) {
      requests.get("geoTIFFRequest").abort();
    }
  }

  const wmsLayers = ref([]);

  const widgetsMapSettings = ref({
    [DashboardWidget.PipelineStatistics]: {
      key: DashboardWidget.PipelineStatistics,
      title: t("dashboard.widgets.dashboardStats.maximize_title"),
      initialX: 0,
      initialY: 0,
      initialWidth: 500,
      initialHeight: 670,
      getInitialX: (container, widgets) =>
        container.offsetWidth -
        (widgets[DashboardWidget.PipelineStatistics].initialWidth + 5),
      getInitialY: (container, widgets) =>
        container.offsetHeight -
        (widgets[DashboardWidget.PipelineStatistics].initialHeight + 30),
      initiallyMinimized: false,
      hidden: computed(
        () => !(selectedPipeline.value && !isLoadingStats.value)
      ),
    },

    [DashboardWidget.HeightProfileChart]: {
      key: DashboardWidget.HeightProfileChart,
      title: t("dashboard.widgets.heightProfile.maximize_title"),
      initialX: 0,
      initialY: 0,
      initialWidth: 700,
      initialHeight: 400,
      getInitialX: (container, widgets) =>
        container.offsetHeight -
        (widgets[DashboardWidget.HeightProfileChart].initialWidth + 15),
      getInitialY: () => 150,
      initiallyMinimized: true,
      hidden: computed(
        () => !(selectedPipeline.value && !isLoadingStats.value)
      ),
    },

    [DashboardWidget.WMSWidget]: {
      key: DashboardWidget.WMSWidget,
      title: t("dashboard.widgets.color.maximize_title"),
      initialX: 0,
      initialY: 0,
      initialWidth: 350,
      initialHeight: "auto",
      getInitialX: (container, widgets) =>
        (container.offsetWidth -
          widgets[DashboardWidget.WMSWidget].initialWidth) /
        2,
      getInitialY: () => 3,
      initiallyMinimized: false,
    },

    [DashboardWidget.WMSLegend]: {
      key: DashboardWidget.WMSLegend,
      title: t("wms_layers.layers"),
      initialWidth: 250,
      initialHeight: 365,
      getInitialX: () => 5,
      getInitialY: () => 180,
      initiallyMinimized: false,
      hidden: wmsLayers.value.length === 0,
    },
  });
  function updateWidgetsMapSettings(
    newSettings: typeof widgetsMapSettings.value
  ) {
    Object.entries(newSettings).forEach(([key, value]) => {
      widgetsMapSettings.value[key] = {
        ...widgetsMapSettings.value[key],
        ...value,
      };
    });
  }

  return {
    isLoadingPipelines,
    isLoadingStats,
    groupPipelines,
    selectedPipelineId,
    pipelineElevation,
    isLoadingPipelineElevation,
    setIsLoadingPipelineElevation,
    comments,
    selectedInspectionId,
    selectedInspectionStats,
    selectedPipeline,
    getAllDataForSelectedPipelineId,
    resetSelectedPipelineIdAndAllDataForIt,
    convertedPipelineElevation,
    convertedPipelineDepth,
    heightRange,
    convertedHeightProfileDistance,
    convertedHeightProfileZ,
    pipelineDepth,
    setIsLoadingPipelines,
    setIsLoadingStats,
    setGroupPipelines,
    setSelectedPipelineId,
    setSelectedPipelineIdFromURL,
    setPipelineElevation,
    setComments,
    updateComment,
    removeComment,
    setSelectedInspectionId,
    setSelectedInspectionStats,
    getPipelines,
    getElevation,
    getPipeComments,
    getInspectionStats,
    cancelRequests,
    wmsLayers,
    widgetsMapSettings,
    updateWidgetsMapSettings,
  };
});

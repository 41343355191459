import { useI18n } from "vue-i18n";
import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { i18n } from "@/config/i18n";
import {
  DigUpSelector,
  getDataForDigupRequest,
} from "@/features/dig-up/helpers";
import { unitsConverter } from "@/config/units";
import {
  getMaxAngle,
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { getQuantile } from "@/pages/joints/helpers";
import { auth0 } from "@/config/auth0";
import { generateDigupSheet } from "@/features/dig-up/api";
import { useAuthStore } from "./useAuthStore";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { usePipelinesStore } from "./usePipelinesStore";
import { useMapStore } from "./useMapStore";
import { useSettingsStore } from "./useSettingsStore";
import { mergeChanges } from "@/utils/objects";
import type { Joint, JointFilters, JointsGradientMods } from "@/types/joints";
import { showToastError } from "@/utils/errors";
import { useStoreUnconfirmedFilters } from "@/utils/storeHelpers";
import { JointsWidget } from "@/pages/joints/views/JointsMapmode/config";
import type { WidgetsSettingsType } from "@/types/widgets";
import {
  GRADIENT_TYPES_DICTIONARY_KEY,
  GRADIENT_TYPES_DISPLAY_DECORATORS,
  GradientType,
} from "@/pages/joints/config";

const INITIAL_JOINT_FILTERS: JointFilters = {
  selectedJointTypes: [],
  selectedMaterials: [],
  selectedVerticalAngle: 0,
  selectedHorizontalAngle: 0,
  selectedGapMean: [],
  selectedGapMax: [],
  plotlyFilter: {
    property: "",
    range: [],
  },
};

const requests = new Map();

export const useJointsStore = defineStore("joints", () => {
  const mapStore = useMapStore();
  const settingsStore = useSettingsStore();
  const pipelinesStore = usePipelinesStore();
  const authStore = useAuthStore();

  const { t } = useI18n();

  const shouldGetNewJoints = ref(true);

  watch(
    () => pipelinesStore.selectedInspectionId,
    () => {
      shouldGetNewJoints.value = true;
    }
  );

  const joints = ref<Joint[]>([]);

  const jointsWithConvertedUnits = computed(() =>
    joints.value.map((joint) => {
      return {
        ...joint,
        distance:
          joint.distance &&
          unitsConverter.instance.convert(joint.distance, "m"),
        gap_width_max:
          joint.gap_width_max &&
          unitsConverter.instance.convert(joint.gap_width_max, "mm"),
        gap_width_mean:
          joint.gap_width_mean &&
          unitsConverter.instance.convert(joint.gap_width_mean, "mm"),
      };
    })
  );
  const jointsWithIds = computed(() => {
    const sortedJoints = jointsWithConvertedUnits.value.sort(
      (a, b) => a.distance - b.distance
    );
    return sortedJoints.map((joint, index) => ({
      ...joint,
      joint_id: index + 1,
    }));
  });
  const jointTypes = computed(() =>
    getUniqueValues(joints.value, "joint_type")
  );
  const materials = computed(() => getUniqueValues(joints.value, "material"));
  const maxVerticalAngle = computed(() =>
    getMaxAngle(joints.value, "angle_vertical")
  );
  const maxHorizontalAngle = computed(() =>
    getMaxAngle(joints.value, "angle_horizontal")
  );
  const quantileVertical = computed(() =>
    getQuantile(
      joints.value.map((j) => j["angle_vertical"]),
      0.99
    )
  );
  const quantileHorizontal = computed(() =>
    getQuantile(
      joints.value.map((j) => j["angle_horizontal"]),
      0.99
    )
  );
  const minMaxGapMean = computed(() =>
    getMinMaxValue(jointsWithIds.value, "gap_width_mean")
  );
  const minMaxGapMax = computed(() =>
    getMinMaxValue(jointsWithIds.value, "gap_width_max")
  );

  function setJoints(value: Joint[]) {
    joints.value = value;
  }
  async function getJoints() {
    try {
      if (!pipelinesStore.selectedInspectionId)
        throw new Error("no selected inspection id in pipelines store");
      setIsLoading(true);
      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();
      requests.set(
        "jointsRequest",
        DefaultService.readJointsInlineInspectionsJointsInspectionIdGroupGet(
          pipelinesStore.selectedInspectionId,
          authStore.selectedGroup
        )
      );
      const joints = await requests.get("jointsRequest");
      requests.delete("jointsRequest");
      setJoints(joints);
      initFilters();

      shouldGetNewJoints.value = false;
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const jointFilters = ref<JointFilters>(
    structuredClone(INITIAL_JOINT_FILTERS)
  );
  const hasActiveFilters = computed(() => {
    return (
      jointFilters.value.selectedJointTypes.length > 0 ||
      jointFilters.value.selectedMaterials.length > 0 ||
      jointFilters.value.selectedVerticalAngle !== maxVerticalAngle.value ||
      jointFilters.value.selectedHorizontalAngle !== maxHorizontalAngle.value ||
      jointFilters.value.selectedGapMean[0] !== minMaxGapMean.value[0] ||
      jointFilters.value.selectedGapMean[1] !== minMaxGapMean.value[1] ||
      jointFilters.value.selectedGapMax[0] !== minMaxGapMax.value[0] ||
      jointFilters.value.selectedGapMax[1] !== minMaxGapMax.value[1]
    );
  });
  const filteredJoints = computed(() => {
    const {
      selectedJointTypes,
      selectedMaterials,
      selectedVerticalAngle,
      selectedHorizontalAngle,
      selectedGapMax,
      selectedGapMean,
      plotlyFilter,
    } = jointFilters.value;

    let filtered = jointsWithIds.value;

    if (selectedJointTypes.length) {
      filtered = valueFiltering(filtered, "joint_type", selectedJointTypes);
    }

    if (selectedMaterials.length) {
      filtered = valueFiltering(filtered, "material", selectedMaterials);
    }

    if (!mapStore.isMapMode) {
      filtered = rangeFiltering(filtered, "angle_vertical", [
        selectedVerticalAngle * -1,
        selectedVerticalAngle,
      ]);

      filtered = rangeFiltering(filtered, "angle_horizontal", [
        selectedHorizontalAngle * -1,
        selectedHorizontalAngle,
      ]);

      filtered = rangeFiltering(filtered, "gap_width_max", selectedGapMax);
      filtered = rangeFiltering(filtered, "gap_width_mean", selectedGapMean);
    }

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;
      filtered = rangeFiltering(filtered, property, range);
    }

    return filtered;
  });
  function setFilters(filters: Partial<JointFilters>) {
    mergeChanges(jointFilters.value, filters);
    jointsSelector.value.clear();
  }
  function initFilters() {
    setFilters({
      selectedVerticalAngle: maxVerticalAngle.value,
      selectedHorizontalAngle: maxHorizontalAngle.value,
      selectedGapMean: minMaxGapMean.value,
      selectedGapMax: minMaxGapMax.value,
    });
  }
  function resetFilters() {
    setFilters(structuredClone(INITIAL_JOINT_FILTERS));
    initFilters();
  }

  const {
    unconfirmedFilters,
    setUnconfirmedFilters,
    confirmUnconfirmedFilters,
    resetUnconfirmedFilters,
    areNewFiltersUnconfirmed,
  } = useStoreUnconfirmedFilters(jointFilters);

  const selectedJointId = ref<Joint["id"] | null>(null);

  const selectedJoint = computed(() =>
    filteredJoints.value.find((joint) => joint.id === selectedJointId.value)
  );

  function setSelectedJointId(jointId: Joint["id"]) {
    selectedJointId.value = jointId;
  }

  const isLoading = ref(true);

  function setIsLoading(value: boolean) {
    isLoading.value = value;
  }

  const isAwaitingDigup = ref(false);

  function setIsAwaitingDigup(value: boolean) {
    isAwaitingDigup.value = value;
  }

  const jointsSelector = ref(new DigUpSelector(i18n.global.locale));

  const areAllJointsSelected = computed(() => {
    return jointsSelector.value.size === filteredJoints.value.length;
  });

  const selectedGradientMode = ref<JointsGradientMods>("degradation_level");

  function setSelectedGradientMode(gradientMode: JointsGradientMods) {
    selectedGradientMode.value = gradientMode;
  }

  // TODO add type for selectedLegend
  const selectedLegend = ref(null);

  function setSelectedLegend(legend) {
    selectedLegend.value = legend;
  }
  function toggleSelectedLegend(legend) {
    if (selectedLegend.value === legend) {
      setSelectedLegend(null);
    } else {
      setSelectedLegend(legend);
    }
  }

  async function getDigupSheet() {
    try {
      setIsAwaitingDigup(true);

      const requestData = getDataForDigupRequest(
        jointsSelector.value.value,
        joints.value,
        settingsStore.locale,
        settingsStore.units
      );

      const token = await auth0.getAccessTokenSilently();
      await generateDigupSheet(token, authStore.selectedGroup, requestData);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsAwaitingDigup(false);
    }
  }

  const showGradient = computed(
    () =>
      !["degradation_level", "material", "joint_type"].includes(
        selectedGradientMode.value
      )
  );
  const gradientTypeDisplayValues = computed(() => {
    function getTypeDisplayValue(key, t) {
      const translatedCaption = t(`${GRADIENT_TYPES_DICTIONARY_KEY}.${key}`);

      if (GRADIENT_TYPES_DISPLAY_DECORATORS[key]) {
        const decorator = GRADIENT_TYPES_DISPLAY_DECORATORS[key];
        return decorator(translatedCaption);
      }

      return translatedCaption;
    }

    const options = {};

    for (const gradientType of Object.values(GradientType)) {
      options[gradientType] = getTypeDisplayValue(gradientType, t);
    }

    return options;
  });
  const histogramTitle = computed(
    () => gradientTypeDisplayValues.value[selectedGradientMode.value]
  );
  const wmsLayers = ref([]);

  const widgetsMapSettings = ref<WidgetsSettingsType>({
    [JointsWidget.JointsTable]: {
      key: JointsWidget.JointsTable,
      title: t("joints.widgets.table.maximize_title"),
      initialWidth: 600,
      initialHeight: 355,
      getInitialX: (container, widgets) =>
        container.offsetWidth -
        (widgets[JointsWidget.JointsTable].initialWidth - 10),
      getInitialY: () => 100,
      initiallyMinimized: false,
    },

    [JointsWidget.HeightProfileChart]: {
      key: JointsWidget.HeightProfileChart,
      title: t("joints.widgets.heightProfile.maximize_title"),
      initialWidth: 655,
      initialHeight: 400,
      getInitialX: (container, widgets) =>
        container.offsetWidth -
        (widgets[JointsWidget.HeightProfileChart].initialWidth + 30),
      getInitialY: (container, widgets) =>
        container.offsetHeight -
        (widgets[JointsWidget.HeightProfileChart].initialHeight + 30),
      initiallyMinimized: true,
    },

    [JointsWidget.Histogram]: {
      key: JointsWidget.Histogram,
      title: histogramTitle.value,
      initialWidth: 655,
      initialHeight: 400,
      getInitialX: () => 5,
      getInitialY: (container, widgets) =>
        container.offsetHeight -
        (widgets[JointsWidget.Histogram].initialHeight + 40),
      initiallyMinimized: false,
    },

    [JointsWidget.GradientLegend]: {
      key: JointsWidget.GradientLegend,
      title: t("joints.widgets.gradient.maximize_title"),
      initialWidth: 130,
      initialHeight: "auto",
      getInitialX: () => 5,
      getInitialY: () => 50,
      initiallyMinimized: false,
      hidden: !showGradient.value,
      resizable: false,
      draggable: false,
      minimizable: false,
    },

    [JointsWidget.ColorsLegend]: {
      key: JointsWidget.ColorsLegend,
      title: histogramTitle.value,
      initialWidth: 230,
      initialHeight: "auto",
      getInitialX: () => 5,
      getInitialY: () => 50,
      initiallyMinimized: false,
      hidden: showGradient.value,
      resizable: false,
      draggable: false,
      minimizable: false,
    },

    [JointsWidget.WMSWidget]: {
      key: JointsWidget.WMSWidget,
      title: t("dashboard.widgets.color.maximize_title"),
      initialWidth: 350,
      initialHeight: "auto",
      getInitialX: (container, widgets) =>
        (container.offsetWidth - widgets[JointsWidget.WMSWidget].initialWidth) /
        2,
      getInitialY: () => 5,
      initiallyMinimized: false,
      resizable: false,
    },

    [JointsWidget.WMSLegend]: {
      key: JointsWidget.WMSLegend,
      title: t("wms_layers.layers"),
      initialWidth: 250,
      initialHeight: 365,
      getInitialX: () => 5,
      getInitialY: () => 180,
      initiallyMinimized: false,
      hidden: wmsLayers.value.length === 0,
    },
  });
  function updateWidgetsMapSettings(
    newSettings: typeof widgetsMapSettings.value
  ) {
    Object.entries(newSettings).forEach(([key, value]) => {
      widgetsMapSettings.value[key] = {
        ...widgetsMapSettings.value[key],
        ...value,
      };
    });
  }

  function cancellAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  }

  return {
    shouldGetNewJoints,
    joints,
    jointFilters,
    unconfirmedFilters,
    areNewFiltersUnconfirmed,
    setUnconfirmedFilters,
    confirmUnconfirmedFilters,
    resetUnconfirmedFilters,
    selectedJointId,
    isLoading,
    isAwaitingDigup,
    jointsSelector,
    selectedGradientMode,
    selectedLegend,
    areAllJointsSelected,
    jointsWithConvertedUnits,
    jointsWithIds,
    jointTypes,
    materials,
    maxVerticalAngle,
    maxHorizontalAngle,
    quantileVertical,
    quantileHorizontal,
    minMaxGapMean,
    minMaxGapMax,
    selectedJoint,
    hasActiveFilters,
    filteredJoints,
    setJoints,
    getJoints,
    setSelectedJointId,
    setFilters,
    initFilters,
    resetFilters,
    setIsLoading,
    setIsAwaitingDigup,
    getDigupSheet,
    cancellAllRequests,
    toggleSelectedLegend,
    setSelectedGradientMode,
    wmsLayers,
    histogramTitle,
    widgetsMapSettings,
    updateWidgetsMapSettings,
  };
});
